import Head, { Props } from './Head';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getTopicData } from 'state/News/selectors';
import { State } from 'state/types';

export default flowRight(
  connect(
    createStructuredSelector<State, Pick<Props, 'topicData'>>({
      topicData: getTopicData,
    }),
  ),
)(Head);
