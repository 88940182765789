import GenericHead from 'components/GenericHead';
import { Helmet } from 'react-helmet';

export type Props = {
  topicData: {
    description: string;
    displayName: string;
  };
  articlesLength: number;
};

export default function TopicDirectory({
  topicData: { description, displayName },
  articlesLength,
}: Props) {
  return (
    <>
      <GenericHead
        description={description}
        noAltLink
        ogType="website"
        socialTitle={displayName}
        title={displayName}
        twitterCard="Summary"
      />
      {articlesLength < 10 ?
        <Helmet meta={[{ content: 'noindex', name: 'robots' }]} />
      : null}
    </>
  );
}
