import Footer from 'components/Footer';
import H4 from 'primitives/Typography/Headings/H4';
import Head from './Head';
import NewsGrid from 'components/NewsGrid';
import PageBody, { ViewName } from 'views/PageBody';
import { Props as GridProps } from 'components/NewsGrid/NewsGrid';
import { NEWS_DIRECTORY_SLUG } from 'state/News/constants';

export type Props = {
  loadNewsArticles: (
    slug: string,
    topicSlug?: string,
    pageResumeParams?: Record<string, unknown> | null,
  ) => void;
  pageResumeParams: Record<string, unknown> | null;
  topicData: {
    description: string;
    displayName: string;
  };
  topicSlug: string;
} & GridProps;

export default function TopicDirectory({
  articles,
  topicSlug,
  pageResumeParams,
  loadNewsArticles,
  hasMoreArticles,
  topicData: { description, displayName },
}: Props) {
  return (
    <>
      <Head articlesLength={articles.length} />
      <PageBody dataTest={ViewName.NewsTopicDirectory} title={displayName}>
        <H4>{description}</H4>
        <NewsGrid
          articles={articles}
          hasMoreArticles={hasMoreArticles}
          loadMore={() =>
            loadNewsArticles(NEWS_DIRECTORY_SLUG, topicSlug, pageResumeParams)
          }
        />
      </PageBody>
      <Footer />
    </>
  );
}
